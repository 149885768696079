var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('span',{staticClass:"font-weight-light text-capitalize text-truncate"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"yellow darken-3","small":"","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-star ")]),_vm._v(" "+_vm._s(_vm.item.defaultPoints)+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.points")))])]),_vm._v(_vm._s(_vm.item.modelName))],1)]),_c('v-divider'),_c('v-img',{staticClass:"white--text align-end",attrs:{"contain":"","height":_vm.imageHeight,"src":_vm.item.pictureUrl},on:{"error":function($event){_vm.item.pictureUrl = require('@/assets/picture-loading-failed-1.png')}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])}),(_vm.showActions)?_c('v-divider'):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(_vm.showActions)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"to":{
              name: 'show-' + [_vm.namespace],
              params: { id: _vm.item.id }
            },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,407876448)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.show")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"to":{
              name: 'edit-' + [_vm.namespace],
              params: { id: _vm.item.id }
            },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,1475826843)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"error--text pa-1",attrs:{"icon":""},on:{"click":function($event){_vm.confirmDeleteDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,false,1537521908)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.delete")))])])],1):_vm._e()],1),(_vm.confirmDeleteDialog)?_c('delete-dialog',{attrs:{"dialog":_vm.confirmDeleteDialog},on:{"update:dialog":function($event){_vm.confirmDeleteDialog=$event},"dialog-cancel":function($event){_vm.confirmDeleteDialog = false},"dialog-update":_vm.deleteItem}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }