<template>
  <v-card class="mx-auto">
    <v-card-title>
      <span class="font-weight-light text-capitalize text-truncate">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="yellow darken-3"
              small
              text-color="white"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small left> mdi-star </v-icon>
              {{ item.defaultPoints }}
            </v-chip>
          </template>
          <span>{{ $t("tooltip.points") }}</span> </v-tooltip
        >{{ item.modelName }}</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-img
      class="white--text align-end"
      contain
      :height="imageHeight"
      :src="item.pictureUrl"
      @error="item.pictureUrl = require('@/assets/picture-loading-failed-1.png')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-divider v-if="showActions"> </v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div v-if="showActions">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :to="{
                name: 'show-' + [namespace],
                params: { id: item.id }
              }"
              class="pa-1"
              icon
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.show") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :to="{
                name: 'edit-' + [namespace],
                params: { id: item.id }
              }"
              class="pa-1"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.edit") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="error--text pa-1"
              icon
              @click="confirmDeleteDialog = true"
            >
              <v-icon color="error">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.delete") }}</span>
        </v-tooltip>
      </div>
    </v-card-actions>

    <delete-dialog
      v-if="confirmDeleteDialog"
      :dialog.sync="confirmDeleteDialog"
      @dialog-cancel="confirmDeleteDialog = false"
      @dialog-update="deleteItem"
    ></delete-dialog>
  </v-card>
</template>

<script>
export default {
  components: {
    DeleteDialog: () => import("@/components/core/dialogs/DeleteDialog")
  },
  data() {
    return {
      confirmDeleteDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    imageHeight: {
      type: String,
      required: false,
      default: "200px"
    },
    limitText: {
      type: String,
      required: false,
      default: "40"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    namespace: {
      type: String,
      required: false,
      default: ""
    }
  },
  methods: {
    deleteItem() {
      this.$emit("delete-item", this.item.id);
      this.confirmDeleteDialog = false;
    }
  }
};
</script>

<style scoped>
.break-all-words >>> p {
  margin-bottom: 0 !important;
}
.break-all-words {
  word-break: break-all;
}
</style>
